import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container, Span } from './QuotationNavItemStyled';

const QuotationNavItem = memo(({ isChecked, isLast, number, text }) => (
  <Container
    checked={isChecked ? 1 : 0}
    last={isLast ? 1 : 0}
    className={classnames(
      {
        'd-none d-md-flex': isLast,
        'd-flex': !isLast,
      },
      'col col-xs-4 justify-content-center'
    )}
  >
    <Span className="mr-1">{`${number}.`}</Span>
    {text}
  </Container>
));

QuotationNavItem.propTypes = {
  isChecked: PropTypes.bool,
  isLast: PropTypes.bool,
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

QuotationNavItem.defaultProps = {
  isChecked: false,
  isLast: false,
};

export default QuotationNavItem;
