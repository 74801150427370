import styled from 'styled-components';
import { Link } from 'gatsby';
import { SECONDARY } from '../../utils/colors';

export const HomeTestimonyLink = styled(Link)`
  color: white;
`;

export const HomeTestimonyWrapper = styled.section`
  background: ${SECONDARY};
  padding: 80px 0;
  color: white;
`;
