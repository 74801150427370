import React, { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Title from '../SectionTitle';
import HomeBenefitsItem from './HomeBenefitsItem';
import contentItems from './contentItems';
import Section from './styled';
import HomeBenefitsLink from './HomeBenefitsLink';

const HomeBenefits = memo(() => (
  <Section>
    <Container>
      <Row className="row mt-5 align-items-center">
        <Col>
          <Title size="small">Descubra as vantagens</Title>
        </Col>
      </Row>
      <Row className="row mt-md-5 align-items-center">
        {contentItems.map(item => (
          <HomeBenefitsItem {...item} key={item.title} />
        ))}
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <HomeBenefitsLink className="btn btn-lg btn-secondary" href="#">
            Comece a usar sem pagar nada
            <span>Encontre o melhor frete para sua encomenda</span>
          </HomeBenefitsLink>
        </Col>
      </Row>
    </Container>
  </Section>
));

export default HomeBenefits;
