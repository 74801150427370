import styled from 'styled-components';
import { PRIMARY } from '../../utils/colors';

export default styled.h3`
  color: ${PRIMARY};
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.2;
`;
