import styled from 'styled-components';

export const Link = styled.a`
  color: ${({ color = 'white' }) => color};
  text-decoration: none;

  &:hover {
    color: ${({ color = 'white' }) => color};
    text-decoration: underline;
  }
`;

export default styled.section`
  background: #342f73;
  padding: 50px 0;
  color: #fff;

  h3 {
    font-size: 1.75rem;
  }
`;
