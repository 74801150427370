import gql from 'graphql-tag';

export const checkRouteQuery = gql`
  query inputs($zipcode_from: String!, $zipcode_to: String!) {
    checkRoute(zipcode_from: $zipcode_from, zipcode_to: $zipcode_to) {
      error
      message
    }
  }
`;

export default checkRouteQuery;
