export default {
  'logo-alfa.png': 'Alfa Transportes',
  'logo-atual.png': 'Atual Cargas',
  'logo-dmari.png': 'Dmari Logística',
  'logo-jadlog.png': 'Jadlog',
  'logo-azul.png': 'Azul Cargo Express',
  'logo-generoso.png': 'Transporte Generoso',
  'logo-excargo.png': 'Excargo',
  'logo-fedex.png': 'FedEx',
  'logo-gnylog.png': 'GNY LOG',
  'logo-panex.png': 'Panex',
  'logo-sulamericana.png': 'SulAmericana',
  'logo-group.png': 'Group Trans',
  'logo-rodomax.png': 'Rodomax Log',
  'logo-transitoBrasil.png': 'Transito Brasil',
  'logo-tw.png': 'TW Transportes',
};
