import styled from 'styled-components';
import { Row } from 'reactstrap';

export default styled(Row)`
  position: relative;

  @media screen and (min-width: 768px) {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      left: -100%;
      height: 3px;
      background-color: #e9ecef;
      z-index: 0;
      top: -0.5rem;
    }
  }
`;
