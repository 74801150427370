export default [
  {
    video: 'https://youtu.be/D14_-2xHkBE',
    name: 'Fernanda Cardoso',
    jobTitle: 'Diretora Administrativa',
    company: 'Asfer Indústria Química',
    text:
      'Eliminamos uma etapa do processo ao integrar nosso sistema (ERP) com a Central do Frete',
  },
  {
    video: 'https://youtu.be/tZaO4JwzA-Y',
    name: 'Pedro Mosca',
    jobTitle: 'Gerente',
    company: 'Pedra Para Pizza',
    text: 'O que demorava 10 minutos fazemos em 10 segundos',
  },
  {
    video: 'https://youtu.be/RFsomo-QKQc',
    name: 'Roseli Boutros',
    jobTitle: 'Diretora de Desenvolvimento',
    company: 'Talski Shoes',
    text:
      'Em questão de segundos temos todas as cotações e decidimos na hora qual a transportadora ideal',
  },
];
