import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Title from '../SectionTitle';
import Subtitle from '../SectionSubtitle';

const HomeAboveTheFold = ({ title, subtitle, children }) => (
  <Container>
    <Row>
      <Col>
        <Title component="h1">{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Col>
    </Row>
    {children}
  </Container>
);

HomeAboveTheFold.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default HomeAboveTheFold;
