import styled from 'styled-components';

export default styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #fff !important;
  line-height: 1.2rem;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 1rem;

  span {
    display: block;
    color: #9e99d5;
    font-size: 11px;
    font-weight: bold;
  }
`;
