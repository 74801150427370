import styled from 'styled-components';

export default styled.section`
  background: #white;
  padding: 80px 0;
`;

export const P = styled.p`
  margin-bottom: 0;
  margin-top: 2px;
  text-transform: uppercase;
  font-size: 13px;
`;
