import React, { useState, memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormFeedback, Spinner } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { Field as FormikField, getIn } from 'formik';
import Field from '../Field';
import useZipcode from '../../hooks/useZipcode';
import Masks from '../../utils/masks';

const ZipcodeField = ({
  label,
  placeholder,
  name,
  setAddress,
  initialValue,
}) => {
  const [zipcode, setZipcode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { fetch } = useZipcode();

  const getClassnames = ({ isFieldValid, isFieldInvalid }) =>
    classnames('form-control form-control-lg', {
      'is-valid': isFieldValid,
      'is-invalid': isFieldInvalid,
    });

  const onValidate = async value => {
    const isSameZipcode = zipcode
      ? zipcode.zipcode.replace('.', '') === value
      : false;
    const canFilter = /\d{5}-\d{3}/.test(value) && !isSameZipcode && !isLoading;

    if (canFilter) {
      setIsLoading(true);

      const result = await fetch(value);

      if (!result) {
        setZipcode(null);
        setAddress('');
        setIsLoading(false);
        return { [name]: 'CEP não encontrado' };
      }
      setZipcode(result);
      setAddress(result.address);
      setIsLoading(false);
    }

    return null;
  };

  const needToShowLoadingMessage = newValue => {
    const cachedZipcode = zipcode && zipcode.zipcode.replace('.', '');
    return isLoading && cachedZipcode !== newValue;
  };

  if (initialValue && !zipcode) {
    onValidate(initialValue);
  }

  return (
    <Field label={label}>
      <FormikField
        name={name}
        validate={onValidate}
        render={({ field, form }) => {
          const isFormSubmitted = form.submitCound > 0;
          const isTouched = isFormSubmitted || getIn(form.touched, field.name);
          const errors = getIn(form.errors, field.name);
          const errorMessage =
            typeof errors === 'object' ? errors[name] : errors;
          const showLoading = needToShowLoadingMessage(field.value);
          const isFieldInvalid = !showLoading && errorMessage;
          const isFieldValid = !showLoading && !isFieldInvalid && zipcode;

          return (
            <>
              <MaskedInput
                mask={Masks.cep}
                placeholder={placeholder}
                className={getClassnames({ isFieldValid, isFieldInvalid })}
                guide={false}
                {...field}
              />
              {showLoading && (
                <>
                  <Spinner size="sm" color="primary" />
                  <small className="text-primary">Buscando endereço...</small>
                </>
              )}
              {isFieldValid && (
                <FormFeedback valid>{zipcode.address}</FormFeedback>
              )}
              {isFieldInvalid && isTouched && (
                <FormFeedback>{errorMessage}</FormFeedback>
              )}
            </>
          );
        }}
      />
    </Field>
  );
};

ZipcodeField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  setAddress: PropTypes.func,
  initialValue: PropTypes.string,
};

ZipcodeField.defaultProps = {
  label: '',
  placeholder: null,
  setAddress: () => {},
  initialValue: undefined,
};

export default memo(ZipcodeField);
