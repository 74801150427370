import phone, { cell, landline } from './phone';
import cep from './cep';
import { cpf, cpfOrCnpj, cnpj } from './documents';

export default {
  cell,
  cep,
  cnpj,
  cpf,
  cpfOrCnpj,
  landline,
  phone,
};
