import React, { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Section, { CardList, Card, CardImage, CardContent } from './styled';
import Title from '../SectionTitle';
import cargoTypes from './cargoTypes';

const HomeCargoType = () => {
  return (
    <Section>
      <Container>
        <Row className="row mt-5 align-items-center">
          <Col>
            <Title size="small">
              A melhor plataforma para envio de cargas de médio e grande porte
            </Title>
          </Col>
        </Row>
        <Row className="row mt-5">
          <Col xs="12">
            <CardList>
              {cargoTypes.map(({ Image, name }) => (
                <Card>
                  <CardImage>
                    <Image />
                  </CardImage>
                  <CardContent>{name}</CardContent>
                </Card>
              ))}
            </CardList>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default memo(HomeCargoType);
