import styled from 'styled-components';

export default styled.section`
  background: #f0f5fa;
  padding: 60px 0;
`;

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Card = styled.article`
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 0.75rem;
`;

export const CardImage = styled.div`
  background: #00c1cd;
  text-align: center;
  width: 84px;
  height: 84px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    opacity: 0.75;
  }
`;

export const CardContent = styled.p`
  font-size: 12px;
  text-align: center;
  margin: 0.75rem 0 0;
`;
