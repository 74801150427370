import styled from 'styled-components';
import { Col } from 'reactstrap';
import { PRIMARY } from '../../utils/colors';

export const Span = styled.span`
  color: ${PRIMARY};
`;

export const Container = styled(Col)`
  position: relative;
  font-size: 0.8rem;
  padding-top: 0.5rem;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: -0.85rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 3px solid ${PRIMARY};
    background-color: ${({ checked }) => (checked ? PRIMARY : 'white')};
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: -0.5rem;
    z-index: 0;
    width: ${({ last }) => (last ? '50%' : '100%')};
    height: 3px;
    background-color: #e9ecef;
  }
`;
