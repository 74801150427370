import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import HomeBenefitsItemTitle from './HomeBenefitsItemTitle';

const HomeBenefitsItem = memo(({ Image, text, title }) => (
  <>
    <Col xs="4" md="2">
      <Image />
    </Col>
    <Col xs="8" md="4">
      <HomeBenefitsItemTitle>{title}</HomeBenefitsItemTitle>
      <p>{text}</p>
    </Col>
  </>
));

HomeBenefitsItem.propTypes = {
  Image: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default HomeBenefitsItem;
