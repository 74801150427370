import gql from 'graphql-tag';

export const fetchZipcodes = gql`
  query fetchZipcodes($like: String!) {
    fetchZipcodes(like: $like) {
      zipcode
      address
    }
  }
`;

export default {
  fetchZipcodes,
};
