import React, { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Title from '../SectionTitle';
import contentItems from './contentItems';
import HomeHowToItem from './HomeHowToItem';
import { StyledSection } from './styled';

const HomeHowTo = memo(() => (
  <StyledSection>
    <Container>
      <Row className="row align-items-center">
        <Col>
          <Title size="small">
            Veja como a Central do Frete facilita sua vida
          </Title>
        </Col>
      </Row>
      {contentItems.map((item, index) => (
        <HomeHowToItem {...item} key={item.title} showArrow={index > 0} />
      ))}
    </Container>
  </StyledSection>
));

export default HomeHowTo;
