import CargaEnderecos from '../../assets/svgs/cargaEnderecos.svg';
import CompareMelhores from '../../assets/svgs/compareMelhores.svg';
import EconomizeTempoDinheiro from '../../assets/svgs/economizeTempoDinheiro.svg';

export default [
  {
    Image: CargaEnderecos,
    title: 'Informe a carga e endereços',
    text: `Você preenche o formulário, informando dados sobre a carga e os endereços.`,
    align: 'left',
    showCTA: false,
  },
  {
    Image: CompareMelhores,
    title: 'Compare as melhores',
    text: `Nosso sistema seleciona as transportadoras, você compara e contrata a melhor opção.`,
    align: 'right',
    showCTA: false,
  },
  {
    Image: EconomizeTempoDinheiro,
    title: 'Economize tempo e dinheiro',
    text: `Você tem certeza de que está economizando tempo e dinheiro: contratar um frete nunca foi tão fácil e rápido!`,
    align: 'left',
    showCTA: true,
  },
];
