import React, { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
import Section, { Link } from './styled';
import { PRIMARY } from '../../utils/colors';

const blogUrl = process.env.BLOG_URL;

const HomeBlog = () => {
  const { allWordpressPost = {} } = useStaticQuery(graphql`
    {
      allWordpressPost(limit: 2) {
        edges {
          node {
            date(formatString: "DD/MM/YYYY")
            title
            link
          }
        }
      }
    }
  `);
  const { edges: posts = [] } = allWordpressPost;

  return (
    <Section>
      <Container>
        <Row>
          {posts.map(({ node: { title, link, date } }) => (
            <Col key={title} className="d-flex flex-column mb-3" sm="6" xs="12">
              <small>{date}</small>
              <h3>
                <Link
                  href={link}
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: title }}
                  rel="noopener noreferrer"
                />
              </h3>
            </Col>
          ))}
        </Row>
        <Row className="mt-3">
          <Col>
            <Link
              href={blogUrl}
              target="_blank"
              color={PRIMARY}
              rel="noopener noreferrer"
            >
              › Acesse nosso blog e tenha acesso aos melhores conteúdos para
              embarcadores
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default memo(HomeBlog);
