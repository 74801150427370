import styled from 'styled-components';

export default styled.section`
  padding: 120px 0 60px;
  background-image: url(${({ background }) => background});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 1600px;

  @media (max-width: 992px) {
    min-height: 450px;
    background-image: none;
    padding: 60px 0;
  }

  @media (max-width: 576px) {
    padding: 30px 0;
  }
`;
