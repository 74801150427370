import MaisConfianca from '../../assets/svgs/maisConfianca.svg';
import FacilComparar from '../../assets/svgs/facilComparar.svg';
import AcompanheAEntrega from '../../assets/svgs/acompanheAEntrega.svg';
import QualidadeNaConversa from '../../assets/svgs/qualidadeNaConversa.svg';

export default [
  {
    Image: MaisConfianca,
    title: 'Mais Confiança',
    text: `Nossas transportadoras são homologadas e avaliadas rigorosamente: é a garantia que sua carga estará em boas mãos.`,
  },
  {
    Image: FacilComparar,
    title: 'É fácil comparar',
    text: `Com poucos cliques, você compara e contrata a transportadora que melhor se encaixa em suas necessidades.`,
  },
  {
    Image: AcompanheAEntrega,
    title: 'Acompanhe a entrega',
    text: `O sistema possibilita o rastreio da carga enviada, tanto pelo remetente quanto pelo destinatário. Transparência do início ao fim!`,
  },
  {
    Image: QualidadeNaConversa,
    title: 'Qualidade na conversa',
    text: `Aqui, a qualidade no atendimento é garantida: somos pessoas conversando com pessoas e facilitando conexões.`,
  },
];
