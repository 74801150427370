import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import * as S from './styled';

const Item = ({
  company,
  jobTitle,
  name,
  text,
  video,
  onPlay,
  onPause,
  isPlaying,
}) => {
  return (
    <Container>
      <Row>
        <Col xs="12" md="6">
          <S.HomeTestimonyItemPlayerWrapper>
            <S.HomeTestimonyItemPlayer
              playing={isPlaying}
              url={video}
              width="100%"
              height={243}
              style={{ background: 'transparent' }}
              onPlay={onPlay}
              onPause={onPause}
              onReady={onPlay}
              light
            />
          </S.HomeTestimonyItemPlayerWrapper>
        </Col>
        <Col xs="12" md="6">
          <h4>{name}</h4>
          <small>
            {jobTitle}
            <S.HomeTestimonyItemBullet />
            {company}
          </small>
          <p>{text}</p>
        </Col>
      </Row>
    </Container>
  );
};

Item.propTypes = {
  company: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool,
};

Item.defaultProps = {
  isPlaying: false,
};

export default Item;
