import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const HomeTestimonyItemBullet = styled.span`
  background-color: white;
  display: inline-block;
  padding: 0.1rem;
  border-radius: 100%;
  margin: 0.1rem 0.5rem;
`;

export const HomeTestimonyItemPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export const HomeTestimonyItemPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
