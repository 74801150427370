import styled from 'styled-components';
import { PRIMARY, SECONDARY_LIGHT } from '../../utils/colors';

export const StyledSection = styled.section`
  padding: 80px 0 0;
  background-color: ${SECONDARY_LIGHT};
`;

export const StyledSubtitle = styled.h4`
  color: ${PRIMARY};
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.2;
`;

export const StyledArrowWrapper = styled.div`
  position: absolute;
  left: 50%;
  width: 150px;
  margin: -30px 0 0 -150px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export default {
  StyledSection,
  StyledSubtitle,
  StyledArrowWrapper,
};
