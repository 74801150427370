import React, { memo } from 'react';
import PropTypes from 'prop-types';
import QuotationNavItem from './QuotationNavItem';
import Nav from './QuotationNavStyled';

const items = [
  "Informe os CEP's",
  'Informe os dados da carga',
  'Veja o melhor valor',
  'Descomplique o seu frete',
];

const QuotationNav = memo(({ currentStep }) => (
  <Nav className="mt-5 mb-5">
    {items.map((text, index) => (
      <QuotationNavItem
        key={index.toString()}
        text={text}
        number={index + 1}
        isChecked={currentStep > index}
        isLast={index === items.length - 1}
      />
    ))}
  </Nav>
));

QuotationNav.propTypes = {
  currentStep: PropTypes.number,
};

QuotationNav.defaultProps = {
  currentStep: 1,
};

export default QuotationNav;
