import { graphql, useStaticQuery } from 'gatsby';
import { filter, includes } from 'lodash';

export const useImagesFluid = ({ names }) => {
  const {
    allImageSharp: { edges = [] },
  } = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
              originalName
            }
          }
        }
      }
    }
  `);

  return filter(edges, ({ node }) => includes(names, node.fluid.originalName));
};

export default {
  useImagesFluid,
};
