import React, { useEffect, memo, useState, useCallback } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  Col,
  Container,
  Row,
} from 'reactstrap';
import * as S from './styled';
import Title from '../SectionTitle';
import { PRIMARY } from '../../utils/colors';
import testimonies from './testimonyList';
import HomeTestimonyItem from './HomeTestimonyItem';

const HomeShippingCarrier = memo(() => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasAutoplay, setAutoplay] = useState(true);

  const next = useCallback(
    disconsiderAutoplay => {
      if (hasAutoplay || disconsiderAutoplay) {
        const isLast = activeIndex === testimonies.length - 1;
        setActiveIndex(isLast ? 0 : activeIndex + 1);
      }
    },
    [activeIndex, hasAutoplay]
  );

  const previous = useCallback(
    disconsiderAutoplay => {
      if (hasAutoplay || disconsiderAutoplay) {
        const isFirst = activeIndex === 0;
        setActiveIndex(isFirst ? testimonies.length - 1 : activeIndex - 1);
      }
    },
    [activeIndex, hasAutoplay]
  );

  const handleClickControls = callback => () => {
    setAutoplay(true);
    callback(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasAutoplay) {
        next();
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [hasAutoplay, next]);

  return (
    <S.HomeTestimonyWrapper>
      <Container>
        <Row className="row mt-5 align-items-center">
          <Col>
            <Title size="small" color={PRIMARY}>
              Já tem muita gente descomplicando
            </Title>
          </Col>
        </Row>
        <Row className="row mt-5">
          <Col xs="12">
            <Carousel
              pause={false}
              interval={false}
              next={next}
              previous={previous}
              activeIndex={activeIndex}
            >
              {testimonies.map((item, index) => (
                <CarouselItem key={index.toString()}>
                  <HomeTestimonyItem
                    {...item}
                    isPlaying={!hasAutoplay && index === activeIndex}
                    onPlay={() => setAutoplay(false)}
                    onPause={() => setAutoplay(true)}
                  />
                </CarouselItem>
              ))}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={handleClickControls(previous)}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={handleClickControls(next)}
              />
            </Carousel>
          </Col>
        </Row>
        <Row className="row mt-5 text-center">
          <Col>
            <S.HomeTestimonyLink
              to="/cotacao-de-frete-online"
              className="btn btn-lg btn-primary"
            >
              Descomplique seu frete
            </S.HomeTestimonyLink>
          </Col>
        </Row>
      </Container>
    </S.HomeTestimonyWrapper>
  );
});

export default HomeShippingCarrier;
