import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

const Field = ({ label, children }) => (
  <FormGroup>
    <>
      {label && <Label size="sm">{label}</Label>}
      {children}
    </>
  </FormGroup>
);

Field.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string,
};

Field.defaultProps = {
  label: '',
};

export default Field;
