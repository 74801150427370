import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const HomeVideoWrapper = styled.section`
  background: #f8fafd;
  padding: 50px 0;
`;

export const HomeVideoPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export const HomeVideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
