import { head, get } from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { fetchZipcodes as query } from '../../queries/zipcode';

const useZipcode = () => {
  const { refetch } = useQuery(query, {
    variables: {
      like: '',
    },
    skip: true,
  });

  const fetch = async value => {
    const { data } = await refetch({ like: value });
    const fetchZipcodes = get(data, 'fetchZipcodes', []);
    return head(fetchZipcodes);
  };

  return {
    fetch,
  };
};

export default useZipcode;
