import CargotypeAnilha from '../../assets/svgs/cargotype_anilha.svg';
import CargotypeEsteira from '../../assets/svgs/cargotype_esteira.svg';
import CargotypeBicicleta from '../../assets/svgs/cargotype_bicicleta.svg';
import CargotypeMoveis from '../../assets/svgs/cargotype_moveis.svg';
import CargotypeEpi from '../../assets/svgs/cargotype_epi.svg';
import CargotypePorta from '../../assets/svgs/cargotype_porta.svg';
import CargotypeAutopecas from '../../assets/svgs/cargotype_autopecas.svg';
import CargotypeBrindes from '../../assets/svgs/cargotype_brindes.svg';
import CargotypeMuitomais from '../../assets/svgs/cargotype_muitomais.svg';

export default [
  { Image: CargotypeAnilha, name: 'Anilha' },
  { Image: CargotypeEsteira, name: 'Esteira' },
  { Image: CargotypeBicicleta, name: 'Bicicleta' },
  { Image: CargotypeMoveis, name: 'Móveis' },
  { Image: CargotypeEpi, name: 'EPI' },
  { Image: CargotypePorta, name: 'Porta' },
  { Image: CargotypeAutopecas, name: 'Auto peças' },
  { Image: CargotypeBrindes, name: 'Brindes' },
  { Image: CargotypeMuitomais, name: 'E muito mais' },
];
