import client from '../apollo/client';
import query from '../queries/checkRoutes';

export default function checkRoutes({ from, to }) {
  return client
    .query({
      variables: { zipcode_from: from, zipcode_to: to },
      query,
    })
    .then(({ data }) => data.checkRoute);
}
