import React, { memo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as S from './styled';
import Title from '../SectionTitle';

const HomeVideo = memo(() => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <S.HomeVideoWrapper>
      <Container>
        <Row className="row mt-5 mb-5 align-items-center">
          <Col>
            <Title size="small">Conheça a Central do Frete</Title>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs="12">
            <S.HomeVideoPlayerWrapper>
              <S.HomeVideoPlayer
                url="https://www.youtube.com/watch?v=wTSU_yi8B6g"
                width="560"
                height="315"
                light
                playing={isPlaying}
                onReady={() => setIsPlaying(true)}
              />
            </S.HomeVideoPlayerWrapper>
          </Col>
        </Row>
      </Container>
    </S.HomeVideoWrapper>
  );
});

export default HomeVideo;
