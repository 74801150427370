import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import HomeAboveTheFold from '../../components/HomeAboveTheFold';
import HomeAboveTheFoldWrapper from '../../components/HomeAboveTheFold/HomeAboveTheFoldWrapper';
import HomeHowTo from '../../components/HomeHowTo';
import HomeVideo from '../../components/HomeVideo';
import HomeBenefits from '../../components/HomeBenefits';
import HomeCargoType from '../../components/HomeCargoType';
import HomeShippingCarrier from '../../components/HomeShippingCarrier';
import HomeTestimony from '../../components/HomeTestimony';
import HomeBlog from '../../components/HomeBlog';
import QuotationNav from '../../components/Quotation/QuotationNav';
import QuotationAddress from '../../components/Quotation/QuotationAddress';
import useUtm from '../../hooks/useUtm';

const IndexPage = ({ location }) => {
  useUtm(location.search);

  const { file = {} } = useStaticQuery(graphql`
    {
      file(name: { in: "homeAboveTheFold" }) {
        publicURL
      }
    }
  `);

  const { publicURL: backgroundImageUrl = '' } = file;

  return (
    <Layout location={location}>
      <SEO
        title="Central do Frete - Cotação de Frete Online"
        description="Encontre o frete ideal e faça sua cotação de frete muito mais rápida e descomplicada."
      />
      <HomeAboveTheFoldWrapper background={backgroundImageUrl} className="mt-4">
        <HomeAboveTheFold
          title="Cotação de Frete Online ideal para sua empresa!"
          subtitle="A sua experiência em frete muito mais rápida e descomplicada."
        >
          <Row>
            <Col md="8">
              <QuotationNav currentStep={1} />
              <QuotationAddress hashKey="ceps" />
            </Col>
          </Row>
        </HomeAboveTheFold>
      </HomeAboveTheFoldWrapper>
      <HomeHowTo />
      <HomeBenefits />
      <LazyLoad offset={700} once>
        <HomeVideo />
      </LazyLoad>
      <HomeCargoType />
      <HomeShippingCarrier />
      <LazyLoad offset={1200} once>
        <HomeTestimony />
      </LazyLoad>
      <HomeBlog />
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default IndexPage;
