import React from 'react';
import { get } from 'lodash';
import { Formik } from 'formik';
import classnames from 'classnames';
import * as Yup from 'yup';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { navigate } from 'gatsby';
import ZipcodeField from '../ZipcodeField';
import checkRoutes from '../../api/checkRoutes';
import { registerClick, PRE_QUOTATION_ADDRESS } from '../../api/gtm';

const cepRegex = new RegExp(/\d{5}-\d{3}/);

const validationSchema = Yup.object().shape({
  from: Yup.string()
    .required('É obrigatório')
    .matches(cepRegex, 'Informe um CEP válido'),
  to: Yup.string()
    .required('É obrigatório')
    .matches(cepRegex, 'Informe um CEP válido'),
});

const QuotationAddress = () => {
  const initialValues = {
    from: '',
    to: '',
  };

  const onSubmitSuccess = values => {
    registerClick({ event: PRE_QUOTATION_ADDRESS });
    navigate('/cotacao-de-frete-online', {
      state: values,
    });
  };

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    setSubmitting(true);
    try {
      const { error, message } = await checkRoutes(values);
      if (error) {
        setErrors({ from: message, to: ' ' });
      } else {
        onSubmitSuccess(values);
      }
    } catch (e) {
      setErrors({ from: get(e, 'graphQLErrors[0].message', null), to: ' ' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange={false}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Row className="form-row d-flex align-items-start">
            <Col>
              <ZipcodeField
                label="CEP Remetente"
                placeholder="CEP de Origem"
                name="from"
              />
            </Col>
            <Col>
              <ZipcodeField
                label="CEP Destinatário"
                placeholder="CEP de Destino"
                name="to"
              />
            </Col>
            <Col xs="12" sm style={{ paddingTop: '2.3rem' }}>
              <FormGroup>
                <Button
                  type="submit"
                  size="lg"
                  block
                  className={classnames({
                    'd-flex justify-content-center align-items-center': isSubmitting,
                  })}
                >
                  {' '}
                  {isSubmitting ? (
                    <>
                      <Spinner size="sm" color="light" />
                      <small> Carregando</small>
                    </>
                  ) : (
                    <React.Fragment>
                      <span>Próximo </span>
                      <span className="d-sm-none d-lg-inline">passo</span>
                    </React.Fragment>
                  )}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default QuotationAddress;
